<template>
  <div class="space-y-4">
    <h3 class="subheader">
      {{ $t("screens.inventory.Main.overview.title") }}
    </h3>
    <br />
    <dl class="mt-5 grid sm:grid-cols-3 md:grid-cols-4  gap-4">
      <div
        class="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-auto"
      >
        <dt>
          <div
            class="absolute bg-indigo-500 rounded-md p-3 flex justify-around"
          >
            <asom-icon class="h-6 w-6 text-white" icon="tikcode"></asom-icon>
          </div>
          <p class="ml-16 text-sm font-medium text-gray-500">
            Out of Stock
          </p>
        </dt>
        <dd class="ml-16 pb-6 flex items-baseline sm:pb-7">
          <p class="text-2xl font-semibold text-gray-900">
            {{ cards.outOfStockCount }}
          </p>
          <div class="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
            <div class="text-sm" v-if="cards.outOfStockCount > 0">
              <button
                @click="viewItems(mainPageItemTypes.outOfStock.VALUE)"
                class="font-medium text-indigo-600 hover:text-indigo-500"
              >
                View Items
              </button>
            </div>
          </div>
        </dd>
      </div>
      <div
        class="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-auto"
      >
        <dt>
          <div
            class="absolute bg-indigo-500 rounded-md p-3 flex justify-around"
          >
            <asom-icon class="h-6 w-6 text-white" icon="timelapse"></asom-icon>
          </div>
          <p class="ml-16 text-sm font-medium text-gray-500">
            Low Inventory
          </p>
        </dt>
        <dd class="ml-16 pb-6 flex items-baseline sm:pb-7">
          <p class="text-2xl font-semibold text-gray-900">
            {{ cards.lowInventoryCount }}
          </p>
          <div class="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
            <div class="text-sm" v-if="cards.lowInventoryCount > 0">
              <button
                @click="viewItems(mainPageItemTypes.lowInventory.VALUE)"
                class="font-medium text-indigo-600 hover:text-indigo-500"
              >
                View Items
              </button>
            </div>
          </div>
        </dd>
      </div>
      <div
        class="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-auto"
      >
        <dt>
          <div
            class="absolute bg-indigo-500 rounded-md p-3 flex justify-around"
          >
            <asom-icon class="h-6 w-6 text-white" icon="overflow"></asom-icon>
          </div>
          <p class="ml-16 text-sm font-medium text-gray-500">
            Near Expiry
          </p>
        </dt>
        <dd class="ml-16 pb-6 flex items-baseline sm:pb-7">
          <p class="text-2xl font-semibold text-gray-900">
            {{ cards.nearExpireCount }}
          </p>
          <div class="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
            <div class="text-sm" v-if="cards.nearExpireCount > 0">
              <button
                @click="viewItems(mainPageItemTypes.nearExpiry.VALUE)"
                class="font-medium text-indigo-600 hover:text-indigo-500"
              >
                View Items
              </button>
            </div>
          </div>
        </dd>
      </div>
      <div
        class="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-auto"
      >
        <dt>
          <div
            class="absolute bg-indigo-500 rounded-md p-3 flex justify-around"
          >
            <asom-icon class="h-6 w-6 text-white" icon="danger"></asom-icon>
          </div>
          <p class="ml-16 text-sm font-medium text-gray-500">
            Expired Stock
          </p>
        </dt>
        <dd class="ml-16 pb-6 flex items-baseline sm:pb-7">
          <p class="text-2xl font-semibold text-gray-900">
            {{ cards.expiredStockCount }}
          </p>
          <div class="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
            <div class="text-sm" v-if="cards.expiredStockCount > 0">
              <button
                @click="viewExpiredItems"
                class="font-medium text-indigo-600 hover:text-indigo-500"
              >
                View Items
              </button>
            </div>
          </div>
        </dd>
      </div>
    </dl>
    <asom-card title="Recent Transactions">
      <asom-client-table
        v-if="!isLoading"
        :columns="[
          'transactionDate',
          'officerPersonNo',
          'transactionNo',
          'transactionType',
          'inventoryTypeName',
          'officerName',
          'amount',
          'orderNo',
        ]"
        :sortableColumns="[
          'transactionDate',
          'officerPersonNo',
          'transactionNo',
          'transactionType',
          'inventoryTypeName',
          'officerName',
          'amount',
          'orderNo',
        ]"
        :data="transactionLogs"
        :pagination="true"
        :searchableDateColumns="['transactionDate']"
      >
        <template v-slot:header_amount>Quantity</template>
        <template v-slot:transactionDate="scopedSlots">
          <span>{{ displayUtcDate(scopedSlots.data) }}</span>
        </template>
        <template v-slot:orderNo="scopedSlots">
          <span>{{ scopedSlots.data ? scopedSlots.data : "-" }}</span>
        </template>
      </asom-client-table>
      <div v-else class="text-center">
        <asom-icon icon="spinner" class="animate-spin" />
      </div>
    </asom-card>
    <item-list-modal
      v-model="showItemList"
      :outOfStockInventoryList="itemLists.outOfStockInventoryList"
      :lowInventoriesList="itemLists.lowInventoriesList"
      :nearExpiryInventoriesList="itemLists.nearExpiryInventoriesList"
      :expiredInventoriesList="itemLists.expiredInventoriesList"
      :selectedSource="selectedViewItemType"
    />
  </div>
</template>

<script>
import get from "lodash.get";
import { mapGetters } from "vuex";
import { getInventoryMainPageData } from "../../services/inventory.service";
import { displayUtcDate } from "@/helpers/dateTimeHelpers";
import ItemListModal from "./_ItemListModal.vue";
import { mainPageItemTypes } from "../../constants/APIEnums/cashManagementEnums";

export default {
  name: "InventoryMainPage",
  components: {
    ItemListModal,
  },
  data() {
    return {
      mainPageItemTypes: mainPageItemTypes,
      error: null,
      isLoading: false,
      cards: {
        outOfStockCount: null,
        lowInventoryCount: null,
        nearExpireCount: null,
        expiredStockCount: null,
      },
      itemLists: {
        outOfStockInventoryList: [],
        lowInventoriesList: [],
        nearExpiryInventoriesList: [],
        expiredInventoriesList: [],
      },
      showItemList: false,
      selectedViewItemType: null,
      transactionLogs: [],
    };
  },
  computed: {
    ...mapGetters({
      stationId: "selectedStation/id",
      lineId: "auth/userLineId",
    }),
  },
  mounted() {
    this.getPageData();
  },
  methods: {
    displayUtcDate,
    viewItems(type) {
      this.showItemList = true;
      this.selectedViewItemType = type;
    },
    viewExpiredItems() {
      this.$router.push({name:'Expired Inventory List'});
    },
    async getPageData() {
      this.isLoading = true;
      const resp = await getInventoryMainPageData({
        stationId: this.stationId,
        lineId: this.lineId,
      });
      if (resp.success) {
        this.setData(get(resp, "payload", {}));
        this.transactionLogs = get(resp.payload, "list", []);
        this.itemLists.outOfStockInventoryList = get(
          resp.payload,
          "outOfStockInventoryList",
          []
        );
        this.itemLists.lowInventoriesList = get(
          resp.payload,
          "lowInventoriesList",
          []
        );
        this.itemLists.nearExpiryInventoriesList = get(
          resp.payload,
          "nearExpiryInventoriesList",
          []
        );
        this.itemLists.expiredInventoriesList = get(
          resp.payload,
          "expiredInventoriesList",
          []
        );
        this.isLoading = false;
        this.error = null;
      } else {
        this.setData({});
        this.isLoading = false;
        this.error = resp.payload;
        this.$scrollTop();
        return;
      }
    },
    setData(data) {
      this.cards.outOfStockCount = get(data, "outOfStockInventories", "-");
      this.cards.lowInventoryCount = get(data, "lowInventories", "-");
      this.cards.nearExpireCount = get(data, "nearExpiryInventories", "-");
      this.cards.expiredStockCount = get(data, "expiredStock", "-");
    },
  },
};
</script>
